import React from 'react'

import './InputButton.css'

const CheckButton = ({ label, ...props }) => {
  return (
    <label className="input-button">
      <input className="input-button__input" type="checkbox" {...props} />
      <span className="input-button__label">{label}</span>
    </label>
  )
}

export default CheckButton
