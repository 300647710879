import tinygradient from 'tinygradient'

export function generateColor(arrayOfColors, steps, position) {
  const gradient = tinygradient(arrayOfColors)
  const step = 1 / steps
  return gradient.rgbAt(position * step)
}

// const colorAt = gradient.rgbAt(3555 * one)

// console.log(colorAt.toHslString())

export function getColor(h, s = 50, l = 50) {
  if (!h) return `hsl(0, 0%, 75%)`
  return `hsl(${h}, ${s}%, ${l}%)`
}

export function melting(element) {
  const one_k = 6000 / 160
  return { background: `hsl(${element.meltingPoint / one_k + 200}, 50%, 50%)` }
}

export function boiling(element) {
  const one_k = 6000 / 160
  return { background: `hsl(${element.boilingPoint / one_k + 200}, 50%, 50%)` }
}

export function electronegativity(element) {
  return { background: getColor(element.electronegativity * 100) }
}

export function firstIonization(element) {
  return {
    background: generateColor(
      ['#6a1181', '#da466a', '#fbfbbe'],
      2500,
      element.firstIonization
    ),
  }
}
