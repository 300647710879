import React from 'react'

import './Checkbox.css'

const Checkbox = ({ label, ...props }) => {
  return (
    <label className="checkbox">
      <input className="checkbox__input" type="checkbox" {...props} />
      <span className="checkbox__label">{label}</span>
    </label>
  )
}

export default Checkbox
