import React, { Component } from 'react'
import './App.css'

import Table from './components/Table'
import Kelvins from './components/Kelvins'
import Sidebar from './components/Sidebar'
import Themes from './components/Themes'
import StyleButtons from './components/StyleButtons'

const defaultParams = {
  number: true,
  symbol: true,
  name: true,
  mass: true,
  empiricalRadius: true,
}

class App extends Component {
  state = {
    showSidebar: false,
    theme: {
      value: 'default',
    },
    params: defaultParams,
    style: {
      cols: true,
      rows: false,
    },
    kelvins: 0,
  }

  // componentDidUpdate(prevProps, prevState) {
  //   if (prevState.showSidebar === false && this.state.showSidebar === true) {
  //     window.addEventListener('click', this.hideSidebar)
  //   }
  // }

  // hideSidebar = ev => {
  //   if (this.sidebar.contains(ev.target)) return
  //   this.setState({ showSidebar: false })
  //   window.removeEventListener('click', this.hideSidebar)
  // }

  toggleSidebar = ev => {
    this.setState({ showSidebar: !this.state.showSidebar })
  }

  changeStyle = ev => {
    const { name, checked } = ev.target
    const style = { ...this.state.style, [name]: checked }
    this.setState({
      style,
    })
  }

  handleChange = ev => {
    const { name, checked } = ev.target
    const params = { ...this.state.params, [name]: checked }
    this.setState({
      params,
    })
  }

  changeTheme = (ev, theme) => {
    this.setState(prev => ({
      theme: {
        value: theme.value,
        style: theme.style,
      },
      params: { ...defaultParams, ...theme.props },
    }))
  }

  changeTemperature = ev => {
    const { value } = ev.target
    this.setState(prev => ({ kelvins: value }))
  }

  render() {
    const appClassName = ['App', `${this.state.theme.value}-theme`]
      .join(' ')
      .trim()

    return (
      <div className={appClassName}>
        <div className="container table-header">
          <Themes
            value={this.state.theme.value}
            changeTheme={this.changeTheme}
          />

          <StyleButtons
            style={this.state.style}
            changeStyle={this.changeStyle}
          />

          <div className="temperature">
            <Kelvins
              value={this.state.kelvins}
              onChange={this.changeTemperature}
            />
          </div>

          <div className="settings-toggle" onClick={this.toggleSidebar}>
            <div style={{ transform: 'translateX(1px)' }}>⚗️</div>
          </div>
        </div>

        <div className="container">
          <Table
            theme={this.state.theme}
            style={this.state.style}
            params={this.state.params}
            kelvins={this.state.kelvins}
          />
        </div>

        <Sidebar
          show={this.state.showSidebar}
          params={this.state.params}
          handleChange={this.handleChange}
        />
      </div>
    )
  }
}

export default App
