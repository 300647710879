import React from 'react'
import './Table.css'
import './Theme.css'

import elements from '../data/data.json'

import Element from './Element'
import GridElement from './GridElement'
import Legend from './Legend'

class Table extends React.Component {
  state = {
    element: 0,
  }

  componentDidUpdate(prevProps, prevState) {
    console.count('Table')
  }

  selectElement = index => {
    this.setState({ element: index })
  }

  searchElement = ev => {
    const { value } = ev.target

    const found = elements.filter(({ name }) => {
      const item = name.toLowerCase()
      return item.indexOf(value) !== -1
    })

    // console.log(found);

    if (found.length === 1) {
      console.log(found[0].name)
      const item = found[0]
      let { number } = item
      this.setState({ element: --number })
    }
  }

  render() {
    // const { kelvins } = this.state
    const { params, theme, style, kelvins } = this.props

    const tableClassName = [
      'table',
      `${theme.value}-theme`,
      style.rows ? 'rows' : '',
      style.cols ? 'cols' : '',
    ]
      .join(' ')
      .trim()

    return (
      <div
        // className="table default-theme"
        className={tableClassName}
      >
        <Legend theme={theme.value} />

        <GridElement columnStart={4} rowStart={1}>
          <Element
            theme={theme}
            style={{
              transform: 'scale(2)',
              transformOrigin: 'top left',
              borderRadius: '4px',
            }}
            element={elements[this.state.element]}
            params={params}
          />
        </GridElement>

        {elements.map((element, i) => {
          return (
            <Element
              theme={theme}
              params={params}
              boiling={kelvins > +element.boilingPoint}
              melting={kelvins > +element.meltingPoint}
              element={element}
              selectElement={ev => this.selectElement(i)}
              key={element.symbol}
            />
          )
        })}

        {/* placeholder for consistent row height */}
        <GridElement columnStart={1} rowStart={8}>
          <Element
            style={{ visibility: 'hidden' }}
            element={elements[this.state.element]}
            params={params}
          />
        </GridElement>
      </div>
    )
  }
}

export default Table
