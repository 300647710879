import React from 'react'

import RadioButton from '../ui/RadioButton'

import THEMES from '../themes'

const Themes = ({ changeTheme, value }) => {
  return (
    <div className="table-header__settings">
      {THEMES.map((theme, i) => (
        <RadioButton
          label={theme.label}
          name="theme"
          value={theme.value}
          checked={value === theme.value}
          onChange={ev => changeTheme(ev, theme)}
          key={i}
        />
      ))}
    </div>
  )
}

export default Themes
