import React from 'react'

const Property = ({ title, value, children }) => {
  return (
    <div className="truncate">
      <div className="element__prop" title={title}>
        {children || value || '–––'}
      </div>
    </div>
  )
}

export default Property
