import React from 'react'
import './Element.css'

import Radius from './element/Radius'
import Property from './element/Property'

const TableElement = ({
  style,
  theme = {},
  params = {},
  boiling,
  melting,
  element,
  selectElement,
}) => {
  const classname = `element element--${element.number} ${element.groupBlock} ${
    element.block
  }-block`

  const themeStyle = {
    ...(typeof theme.style === 'function' && theme.style(element)),
    ...(melting && {
      color: '#fff',
      background: '#2196F3',
    }),
    ...(boiling && {
      color: '#aaa',
      background: '#fff',
    }),
  }

  const {
    number,
    block,
    symbol,
    name,
    nameRu,
    calculatedRadius,
    empiricalRadius,
    covalentRadius,
    vanDerWallsRadius,
    ...otherParams
  } = params

  return (
    <div
      className={classname}
      style={{ ...style, ...themeStyle }}
      onFocus={selectElement}
      tabIndex="0"
    >
      {number && (
        <div className="element__number" title="number">
          {element.number}
        </div>
      )}
      {symbol && (
        <div className="element__symbol" title={element.name}>
          {element.symbol}
        </div>
      )}
      {name && (
        <div className="element__name" title="name">
          {element.name}
        </div>
      )}
      {nameRu && (
        <div className="element__name" title="nameRu">
          {element.nameRu}
        </div>
      )}

      <div className="br" />

      {/* {meltingPoint && <TempGraph kelvins={element.meltingPoint} />} */}

      {Object.keys(otherParams).map(key => {
        return (
          params[key] && <Property value={element[key]} title={key} key={key} />
        )
      })}

      {calculatedRadius && (
        <Radius
          title={`calculatedRadius ${element.calculatedRadius}`}
          value={element.calculatedRadius}
          factor={10}
        />
      )}
      {empiricalRadius && (
        <Radius
          title={`empiricalRadius ${element.empiricalRadius}`}
          value={element.empiricalRadius}
          factor={10}
        />
      )}
      {covalentRadius && (
        <Radius
          title={`covalentRadius ${element.covalentRadius}`}
          value={element.covalentRadius}
          factor={10}
        />
      )}
      {vanDerWallsRadius && (
        <Radius
          title={`vanDerWallsRadius ${element.vanDerWallsRadius}`}
          value={element.vanDerWallsRadius}
          factor={10}
        />
      )}
    </div>
  )
}

export default TableElement
