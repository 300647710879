import React from 'react'

import './Kelvins.css'

const Kelvins = ({ value, onChange }) => {
  return (
    <div className="kelvins">
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>Temperature in Kelvins: </div>
        <div>{value}</div>
      </div>
      <input
        type="range"
        min="0"
        max="6000"
        name="kelvins"
        value={value}
        onChange={onChange}
        style={{ width: '100%' }}
      />
    </div>
  )
}

export default Kelvins
