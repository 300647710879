import React from 'react'
import CheckButton from '../ui/CheckButton'

const StyleButtons = ({ style, changeStyle }) => {
  return (
    <div className="table-header__settings">
      <CheckButton
        label="Columns"
        name="cols"
        // value="cols"
        checked={style.cols}
        onChange={changeStyle}
      />
      <CheckButton
        label="Rows"
        name="rows"
        // value="rows"
        checked={style.rows}
        onChange={changeStyle}
      />
    </div>
  )
}

export default StyleButtons
