import { melting, boiling, electronegativity } from './utils'

const THEMES = [
  {
    label: 'Default',
    value: 'default',
  },
  {
    label: 'Blocks',
    value: 'blocks',
  },
  {
    label: 'Melting',
    value: 'melting',
    props: {
      meltingPoint: true,
    },
    style: el => melting(el),
  },
  {
    label: 'Boiling',
    value: 'boiling',
    props: {
      boilingPoint: true,
    },
    style: el => boiling(el),
  },
  {
    label: 'Electronegativity',
    value: 'electronegativity',
    props: {
      electronegativity: true,
    },
    style: el => electronegativity(el),
  },
  {
    label: 'Black',
    value: 'black',
  },
]

export default THEMES
