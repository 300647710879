import React from "react";

const GridElement = ({ columnStart, rowStart, children, ...props }) => {
  const styles = {
    gridColumnStart: columnStart,
    gridRowStart: rowStart,
    minWidth: 0
  };
  return <div style={styles}>{children}</div>;
};

export default GridElement;
