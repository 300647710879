import React from 'react'

import GridElement from './GridElement'

const Blocks = props => {
  return (
    <>
      <GridElement columnStart={1} rowStart={8}>
        <div className="table-serie color s-block">S-Block</div>
      </GridElement>
      <GridElement columnStart={3} rowStart={9}>
        <div className="table-serie color f-block">F-Block</div>
      </GridElement>
      <GridElement columnStart={3} rowStart={8}>
        <div className="table-serie color d-block">D-Block</div>
      </GridElement>
      <GridElement columnStart={13} rowStart={8}>
        <div className="table-serie color p-block">P-Block</div>
      </GridElement>
    </>
  )
}

const Series = props => {
  return (
    <>
      <GridElement columnStart={1} rowStart={8}>
        <div className="table-serie color alkali metal">Alkali metal</div>
      </GridElement>
      <GridElement columnStart={2} rowStart={8}>
        <div className="table-serie color alkaline earth metal">
          Alkaline earth metal
        </div>
      </GridElement>
      <GridElement columnStart={4} rowStart={8}>
        <div className="table-serie color transition metal">
          Transition metal
        </div>
      </GridElement>
      <GridElement columnStart={13} rowStart={8}>
        <div className="table-serie color post-transition metal">
          Post-​transition metal
        </div>
      </GridElement>
      <GridElement columnStart={14} rowStart={8}>
        <div className="table-serie color metalloid">Metalloid</div>
      </GridElement>
      <GridElement columnStart={15} rowStart={8}>
        <div className="table-serie color nonmetal">Polyatomic nonmetal</div>
      </GridElement>
      <GridElement columnStart={17} rowStart={8}>
        <div className="table-serie color halogen">Halogens</div>
      </GridElement>
      <GridElement columnStart={18} rowStart={8}>
        <div className="table-serie color noble gas">Noble gas</div>
      </GridElement>

      <GridElement columnStart={3} rowStart={9}>
        <div className="table-serie color lanthanoid">Lanthanides</div>
      </GridElement>
      <GridElement columnStart={3} rowStart={10}>
        <div className="table-serie color actinoid">Actinides</div>
      </GridElement>
    </>
  )
}

const Temperature = props => {
  return (
    <>
      <GridElement columnStart={1} rowStart={8}>
        <div className="table-serie" style={{ color: 'hsl(200, 50%, 50%)' }}>
          0K
        </div>
      </GridElement>
      <GridElement columnStart={3} rowStart={8}>
        <div
          className="table-serie table-align--right"
          style={{ color: 'hsl(200, 50%, 50%)' }}
        >
          1000K
        </div>
      </GridElement>
      <GridElement columnStart={6} rowStart={8}>
        <div
          className="table-serie table-align--right"
          style={{ color: 'hsl(232, 50%, 50%)' }}
        >
          2000K
        </div>
      </GridElement>
      <GridElement columnStart={9} rowStart={8}>
        <div
          className="table-serie table-align--right"
          style={{ color: 'hsl(264, 50%, 50%)' }}
        >
          3000K
        </div>
      </GridElement>
      <GridElement columnStart={12} rowStart={8}>
        <div
          className="table-serie table-align--right"
          style={{ color: 'hsl(296, 50%, 50%)' }}
        >
          4000K
        </div>
      </GridElement>
      <GridElement columnStart={15} rowStart={8}>
        <div
          className="table-serie table-align--right"
          style={{ color: 'hsl(328, 50%, 50%)' }}
        >
          5000K
        </div>
      </GridElement>
      <GridElement columnStart={18} rowStart={8}>
        <div
          className="table-serie table-align--right"
          style={{ color: 'hsl(360, 50%, 50%)' }}
        >
          6000K
        </div>
      </GridElement>
    </>
  )
}

const Periods = props => {
  return (
    <>
      <div className="table-period table-period--1">1</div>
      <div className="table-period table-period--2">2</div>
      <div className="table-period table-period--3">3</div>
      <div className="table-period table-period--4">4</div>
      <div className="table-period table-period--5">5</div>
      <div className="table-period table-period--6">6</div>
      <div className="table-period table-period--7">7</div>
    </>
  )
}

const Legend = ({ theme }) => {
  switch (theme) {
    case 'blocks':
      return <Blocks />
    case 'melting':
    case 'boiling':
      return <Temperature />
    default:
      return <Series />
  }
}

export default Legend
