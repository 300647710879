import React from 'react'

import Checkbox from '../ui/Checkbox'

import elements from '../data/data.json'
const SETTINGS = Object.keys(elements[0])

const Sidebar = ({ show, params, handleChange }) => {
  const sidebar = `settings ${show ? 'sidebar--show' : 'sidebar--hide'}`
  return (
    <div className={sidebar}>
      {SETTINGS.map((setting, i) => (
        <Checkbox
          label={setting}
          name={setting}
          checked={params[setting]}
          onChange={handleChange}
          key={i}
        />
      ))}
    </div>
  )
}

export default Sidebar
