import React from 'react'

const Radius = ({ value, factor, ...props }) => {
  return (
    <div
      className="element__radius"
      style={{
        width: `${value / factor}px`,
        height: `${value / factor}px`,
      }}
      {...props}
    />
  )
}

export default Radius
